*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.App {
  text-align: center;
}

.display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* spaces */
.mt1 {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.mt3 {
  margin-top: 3rem;
}
.mt4 {
  margin-top: 4rem;
}
.mt5 {
  margin-top: 5rem;
}
.mt6 {
  margin-top: 6rem;
}
.mt7 {
  margin-top: 7rem;
}
